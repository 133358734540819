import React from "react"
import Card from "@material-ui/core/Card"
import Typography from "@material-ui/core/Typography"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import ListItemText from "@material-ui/core/ListItemText"
import DriveEtaIcon from "@material-ui/icons/DriveEta"
import RoomIcon from "@material-ui/icons/Room"
import styled from "styled-components"
import currencyFormatter from "../helper/currencyFormatter"
import Grid from "@material-ui/core/Grid"
const StyledCard = styled(Card)`
  padding: 0.5rem 1rem;
  background: ${props => props.theme.palette.secondary.main};
  &.active-station,
  &:hover,
  &:active,
  &:focus {
    background: ${props => props.theme.palette.primary.main};
  }
  margin: 0 1rem 1rem 1rem;
  ${props => props.theme.breakpoints.up("md")} {
    margin: 0 1rem 1rem 0rem;
  }
`
const Label = styled(Typography)`
  color: ${props => props.theme.palette.primary.light};
  line-height: 0.8rem;
  svg {
    vertical-align: bottom;
    width: 0.75em;
    height: 0.75em;
    margin-right: 0.5em;
  }
`

const StyledList = styled(List)`
  font-size: 10px;
  color: ${props => props.theme.palette.primary.light};
  * {
    /* disabled ListItem on desktop */
    opacity: 1 !important;
  }
  padding: 0;
`

const StyledListItemIcon = styled(ListItemIcon)`
  min-width: 25px;
  color: ${props => props.theme.palette.primary.light};
  svg {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
  }
`
const StyledListItem = styled(ListItem)`
  padding: 0;
`

const StyledListItemText = styled(ListItemText)`
  padding: 0;
  span {
    font-size: 0.875rem;
  }
`
const StyledLink = styled.a`
  color: ${props => props.theme.palette.secondary.contrastText};
  text-decoration: none;
`

export default function Station(props) {
  const { station, distance, fuel, leading } = props

  const address = `${station.street}, ${
    station.postalCode ? station.postalCode : station.postalcode
  } ${station.geocoded_place}`
  return (
    <StyledCard
      className={leading && "active-station"}
      key={station.identifier}
    >
      <StyledLink
        href={`/${station.url}`}
        title={`${station.brand} in ${address}`}
      >
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <Typography variant="h2" component="div">
              {station.brand}
            </Typography>
            <StyledList>
              <StyledListItem>
                <StyledListItemIcon>
                  <RoomIcon />
                </StyledListItemIcon>
                <StyledListItemText primary={address} />
              </StyledListItem>
              <StyledListItem>
                <StyledListItemIcon>
                  <DriveEtaIcon />
                </StyledListItemIcon>
                <StyledListItemText primary={`${distance} km`} />
              </StyledListItem>
            </StyledList>
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h2" component="div">
              {currencyFormatter(fuel.price)}{" "}
            </Typography>
            <Label variant="caption">Aktuell pro Liter</Label>
          </Grid>
        </Grid>
      </StyledLink>
    </StyledCard>
  )
}
