module.exports.filterStations = function (a) {
  const tenDaysAgo = new Date()
  tenDaysAgo.setDate(tenDaysAgo.getDate() - 10)

  return (
    a &&
    a?.brand?.length > 0 &&
    a?.placecleaned?.length > 0 &&
    a?.countycleaned?.length > 0 &&
    a?.fuels[0]?.price > 0.1 &&
    a?.fuels[1]?.price > 0.1 &&
    a?.fuels[2]?.price > 0.1 &&
    a?.fuels[0]?.price !== a?.fuels[1]?.price &&
    a?.fuels[0]?.price !== a?.fuels[2]?.price &&
    a?.fuels[1]?.price !== a?.fuels[2]?.price &&
    new Date(a?.fuels[0]?.lastChange?.timestamp) > tenDaysAgo &&
    new Date(a?.fuels[1]?.lastChange?.timestamp) > tenDaysAgo &&
    new Date(a?.fuels[2]?.lastChange?.timestamp) > tenDaysAgo
  )
}
