import React, { useEffect, useState } from "react"

import Layout from "../Layout"
import Meta from "../components/Meta"
import styled from "styled-components"
import Footer from "../components/Footer"
import Header from "../components/Header"
import Station from "../components/Station"
import Counties from "../components/Counties"
import Cities from "../components/Cities"
import useFetch from "use-http"
import env from "../../env-config.json"
import useGeolocation from "react-hook-geolocation"
import distance from "../helper/distance"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import Grid from "@material-ui/core/Grid"

import useMediaQuery from "@material-ui/core/useMediaQuery"

import Spacer from "../components/Spacer"
import {
  AdsenseAd,
  AdsenseAdLaptop,
  AdsenseAdMobile,
} from "../components/Adsense"
import Main from "../components/Main"
import LocationMap from "../components/LocationMap"
import { filterStations } from "../helper/filterStations"

const MapWrap = styled.div`
  width: 100%;
  height: 300px;
  ${props => props.theme.breakpoints.up("md")} {
    height: 500px;
    padding-left: 1rem;
  }
`

const StyledFormControlFuelSelect = styled(FormControl)`
  width: calc(100% - 2rem);
  margin: 1rem;
  ${props => props.theme.breakpoints.up("md")} {
    margin: 0 0 1rem 0;
  }
`

const StyledBoxItemList = styled(Box)`
  position: relative;
  ${props => props.theme.breakpoints.up("md")} {
    min-height: 500px;
    max-height: 500px;
    overflow-y: auto;
    scrollbar-color: ${props => props.theme.palette.primary.main};
  }
`

const StyledTypographyH1 = styled(Typography)`
  margin: 1rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  ${props => props.theme.breakpoints.up("md")} {
    margin-bottom: 3rem;
    margin-top: 3rem;
  }
`

const StyledBoxWrapper = styled(Box)`
  ${props => props.theme.breakpoints.up("md")} {
    margin-bottom: 4.5rem;
  }
`

const StyledSelect = styled(Select)`
  ::before {
    border-color: ${props => props.theme.palette.primary.main};
  }
`

export default function IndexPage({ pageContext: props }) {
  const { county, city, counties, cities, type } = props

  const geolocation = useGeolocation()

  //maybe build dynamically based on data.
  const fuels = ["Super E5", "Super E10", "Diesel"]
  const [selectedFuel, setSelectedFuel] = useState(fuels[0])
  const [stations, setStations] = useState(props.stations)

  const [relevance, setRelevance] = useState(env.maps.defaultCenter)
  const { get, response, loading, error } = useFetch(env.api.prices)

  const isUpMd = useMediaQuery(theme => theme.breakpoints.up("sm"))
  const isDownMd = useMediaQuery(theme => theme.breakpoints.down("sm"))

  async function loadRelevantStations() {
    console.log("loadrelevant")

    let relevantStations = []
    let i = 1
    while (relevantStations.length < 7 && i < 10) {
      console.log(i)
      relevantStations = await get(
        `/find/${relevance?.lat?.toString().replace(",", "-")}/${relevance?.lng
          ?.toString()
          .replace(",", "-")}/${i}/gzip`
      )
      console.log(relevantStations)
      relevantStations = relevantStations.filter(filterStations)
      console.log(relevantStations)
      i++
    }

    if (response.ok && relevantStations) {
      setStations(relevantStations)
    }
  }

  useEffect(() => {
    if (!geolocation.latitude || !geolocation.longitude) {
      return
    }
    if (
      geolocation.latitude === relevance.lat ||
      geolocation.longitude === relevance.lng
    ) {
      return
    }

    if (type === "index") {
      setRelevance({
        lat: geolocation.latitude,
        lng: geolocation.longitude,
      })
    }
  }, [geolocation, type, relevance])

  useEffect(() => {
    if (
      relevance.lat === env.maps.defaultCenter.lat ||
      relevance.lng === env.maps.defaultCenter
    ) {
      return
    }

    console.log(relevance)

    loadRelevantStations()
  }, [relevance])

  const filteredStations = stations
    .filter(
      station =>
        station.fuels.filter(fuel => fuel.name === selectedFuel).length > 0
    )
    .sort((station_a, station_b) => {
      const fuel_a = station_a.fuels.filter(
        fuel => fuel.name === selectedFuel
      )[0]
      const fuel_b = station_b.fuels.filter(
        fuel => fuel.name === selectedFuel
      )[0]
      if (fuel_a.price < fuel_b.price) {
        return -1
      }
      if (fuel_a.price > fuel_b.price) {
        return 1
      }
      return 0
    })

  const leading = county || city ? filteredStations?.[0] : null

  const { claim, description } = (() => {
    switch (true) {
      case county && !city:
        return {
          claim: `Deine Tankstellen in ${county}`,
          description: `Die aktuellen Benzinpreise deiner Tankstellen in ${county}.`,
        }
      case city:
        return {
          claim: `Deine Tankstellen in ${city}`,
          description: `Die aktuellen Benzinpreise deiner Tankstellen in ${county}.`,
        }
      default:
        return {
          claim: `Günstig Tanken - Aktuelle Benzin, Diesel, LPG-Gas Preise`,
          description: `Die aktuellen Benzinpreise der Tankstellen in deiner Nähe.`,
        }
    }
  })()

  useEffect(() => {
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [])

  useEffect(() => {
    if (isUpMd) {
      ;(window.adsbygoogle = window.adsbygoogle || []).push({})
    }
  }, [isUpMd])

  useEffect(() => {
    if (isDownMd) {
      ;(window.adsbygoogle = window.adsbygoogle || []).push({})
    }
  }, [isDownMd])

  return (
    <Layout>
      <Meta title={claim} description={description} />

      <Header />
      <Main>
        <StyledBoxWrapper>
          <StyledTypographyH1 variant="h1" align="center">
            {claim}
          </StyledTypographyH1>
          <Grid container spacing={0} justify="flex-start">
            <Box clone order={{ xs: 1, md: 2 }}>
              <Grid item md={7} xs={12}>
                <MapWrap>
                  <LocationMap leading={leading} type={type} />
                </MapWrap>
                {isDownMd && (
                  <AdsenseAdMobile>
                    <ins
                      className="adsbygoogle"
                      style={{ display: "block" }}
                      data-ad-format="fluid"
                      data-ad-layout-key="-ef+6k-30-ac+ty"
                      data-ad-client="ca-pub-9201149966528047"
                      data-ad-slot="6035932643"
                      data-adtest={
                        process.env.NODE_ENV === "development" ? "on" : ""
                      }
                    ></ins>
                  </AdsenseAdMobile>
                )}
              </Grid>
            </Box>

            <Box clone order={{ xs: 2, md: 1 }}>
              <Grid item md={5} xs={12}>
                <StyledBoxItemList>
                  <StyledFormControlFuelSelect variant="filled">
                    <InputLabel>Kraftstoff</InputLabel>
                    <StyledSelect
                      value={selectedFuel}
                      onChange={event => setSelectedFuel(event.target.value)}
                    >
                      {fuels.map(fuel => (
                        <MenuItem value={fuel} key={fuel}>
                          {fuel}
                        </MenuItem>
                      ))}
                    </StyledSelect>
                  </StyledFormControlFuelSelect>
                  {filteredStations.map((station, i) => (
                    <Station
                      key={station.identifier}
                      leading={i === 0}
                      station={station}
                      fuel={
                        station.fuels.filter(
                          fuel => fuel.name === selectedFuel
                        )[0]
                      }
                      distance={distance(station.coords, {
                        lat: geolocation.latitude,
                        lng: geolocation.longitude,
                      })}
                    />
                  ))}
                </StyledBoxItemList>
              </Grid>
            </Box>
          </Grid>
        </StyledBoxWrapper>

        {isUpMd && (
          <AdsenseAdLaptop>
            <ins
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client="ca-pub-9201149966528047"
              data-ad-slot="9871235876"
              data-adtest={
                process.env.NODE_ENV === "development" ? "on" : "off"
              }
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
          </AdsenseAdLaptop>
        )}

        <StyledTypographyH1 variant="h2" align="center">
          Tankstellen nach Bundesland
        </StyledTypographyH1>

        <Counties current={county} counties={counties} />

        <Spacer />
        {county && (
          <>
            <StyledTypographyH1 variant="h2" align="center">
              Tankstellen nach Stadt in {county}
            </StyledTypographyH1>
            <Cities
              county={county}
              current={city}
              counties={counties}
              cities={cities}
            />
          </>
        )}
        <AdsenseAd>
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-9201149966528047"
            data-ad-slot="6513271333"
            data-adtest={process.env.NODE_ENV === "development" ? "on" : "off"}
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </AdsenseAd>
      </Main>
      <Footer />
    </Layout>
  )
}
